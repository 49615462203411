/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://xjh7y2ivdvdn3olbblbxjxdxzq.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qjhkdx5xtvduthycid4dpufzay",
    "aws_cognito_identity_pool_id": "us-west-1:2fe34896-c747-4e91-b541-a3008d904c01",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_1iWjxfeYk",
    "aws_user_pools_web_client_id": "1v88i6n9t74kte8a3ug0pdpc1c",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "GIVEN_NAME",
        "FAMILY_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplifytassel1fd8397fa24b4a9cacd2fce8a2c0c8eb233440-dev",
    "aws_user_files_s3_bucket_region": "us-west-1"
};


export default awsmobile;
